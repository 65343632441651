const Button = {
  baseStyle: {
    color: "primary",
    borderRadius: "0px",
    transition: "all 0.2s ease-in-out",
    fontFamily: `var(--chakra-fonts-heading)`,
  },
  variants: {
    primary: {
      color: "white",
      backgroundColor: "secondary",
      border: "2px solid",
      borderColor: "secondary",
      paddingY: 6,
      paddingX: 6,
      _hover: {
        backgroundColor: "white",
        color: "secondary",
      },
    },
    secondary: {
      color: "secondary",
      backgroundColor: "white",
      border: "2px solid",
      borderColor: "secondary",
      paddingY: 6,
      paddingX: 6,
      _hover: {
        backgroundColor: "secondary",
        color: "white",
      },
    },
    darkGrey: {
      color: "white",
      backgroundColor: "darkGray",
    },
    success: {},
    alert: {},
    warning: {},
    error: {},
    link: {},
    disabled: {},
  },
}

export default Button
