import React from "react"
import { Box } from "@chakra-ui/layout"
// SVG
import LogoWhite from "Svg/LogoWhite"

// @TODO: Bring in data from footer widgets

const Footer = () => (
  <Box
    py={10}
    px={5}
    display="grid"
    placeItems="center"
    bg="black"
    textAlign="center"
    color="white"
  >
    <LogoWhite />
    {/* <Social /> */}
    <Box mt={8}>
      <a href="https://www.google.com/search?q=7550+I-10+STE+100+San+Antonio+TX+78229&oq=7550+I-10+STE+100+San+Antonio+TX+78229&aqs=chrome..69i57j69i60l2.12127j0j4&sourceid=chrome&ie=UTF-8#">
        7550 Interstate 10 W
        <br />
        STE 100
        <br />
        San Antonio, TX 78229
      </a>
      <br />
      <a href="tel:210.582.0475">210-582-0475</a>
    </Box>
  </Box>
)

export default Footer
