import * as React from "react"
import PropTypes from "prop-types"
import {
  Flex,
  Box,
  useColorMode,
  List,
  ListItem,
  useMediaQuery,
  useDisclosure,
  IconButton,
  Button,
} from "@chakra-ui/react"
import Logo from "Svg/Logo"
import Link from "../../Link/Link"
import NavLink from "./NavLink"
import MobileNavContent from "./MobileNavContent"
import { HamburgerIcon } from "@chakra-ui/icons"

const NavBar = ({ children, menu, siteTitle, ...props }) => {
  const { colorMode } = useColorMode()
  // tablet breakpoint
  const [tabletDown] = useMediaQuery("(max-width: 1000px)")
  const mobileNav = useDisclosure()

  const Branding = () => {
    return (
      <>
        {colorMode === "dark" ? (
          <Link to="/" alt={`Home | ${siteTitle}`} aria-label={siteTitle}>
            <Logo alt={siteTitle} width="150" />
          </Link>
        ) : (
          <Link to="/" alt={`Home | ${siteTitle}`} aria-label={siteTitle}>
            <Logo alt={siteTitle} width="150" />
          </Link>
        )}
      </>
    )
  }

  const MenuLinks = ({ isMobile }) => {
    return (
      <List
        display={isMobile ? "block" : "flex"}
        fontSize={isMobile ? "2xl" : ""}
        alignItems={`center`}
        marginTop={isMobile ? 10 : 0}
        textAlign={`center`}
      >
        {menu.nodes.map(node => (
          <ListItem key={node.label} my={[4, 4, 4, 0]}>
            <NavLink to={node.url} alt={node.label} onClick={mobileNav.onClose}>
              {node.label}
            </NavLink>
          </ListItem>
        ))}
        {/* @TODO: Get phone and link from site settings ACF fields to be created */}
        <Button href="tel:210.582.0475" as="a" variant="primary">
          210-582-0475
        </Button>
      </List>
    )
  }
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      px={4}
      py={2}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "primary.700", "primary.700"]}
      {...props}
    >
      {/* <ThemeToggle /> */}
      <Branding />
      <Box alignItems="center" display="flex" position="relative">
        {tabletDown && (
          <IconButton
            display={{
              base: "flex",
            }}
            backgroundColor={"secondary"}
            size="sm"
            aria-label="Open menu"
            fontSize="20px"
            variant="secondary"
            onClick={mobileNav.onOpen}
            icon={<HamburgerIcon color={`white`} />}
          />
        )}
        {!tabletDown && <MenuLinks />}
        {/* <ThemeToggle marginLeft={4} backgroundColor={buttonColor} /> */}
      </Box>
      <MobileNavContent
        isOpen={mobileNav.isOpen}
        onClose={mobileNav.onClose}
        display={{ md: `none` }}
      >
        <MenuLinks isMobile />
      </MobileNavContent>
    </Flex>
  )
}

NavBar.propTypes = {
  menu: PropTypes.object,
  children: PropTypes.node,
}

// NavBar.defaultProps = {
//   siteTitle: ``,
// }

export default NavBar
