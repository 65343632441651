const colors = {
  color: "#707070",
  bg: "#FFFFFF",
  primaryColorScheme: "gray",
  primary: "#201D1D",
  secondary: "#a63933",
  gradientTop: "#FFFFFF",
  gradientBottom: "#FFFFFF",
  headingColor: "black",
  cardBg: "#FFFFFF",
  cardLink: "#000000",
  cardLinkHover: "#2B6CB0",
  black: "#201D1D",
  lightGray: "#d8d7d7",
  dark: {
    color: "rgba(255, 255, 255, 0.80)",
    bg: "#171923",
    primary: "#63B3ED",
    gradientTop: "#171923",
    gradientBottom: "#2D3748",
    headingColor: "white",
    cardBg: "#2D3748",
    cardLink: "#FFFFFF",
    cardLinkHover: "#4299E1",
  },
}

export default colors
