import * as React from "react"
import "@fontsource/montserrat/latin-300.css"
import "@fontsource/montserrat/latin-600.css"
import "@fontsource/raleway/latin-400-italic.css"
import "@fontsource/raleway/latin-400.css"
import "@fontsource/raleway/latin-600.css"
import "@fontsource/raleway/latin-700.css"
import { useStaticQuery, graphql } from "gatsby"
import { ChakraProvider, Box } from "@chakra-ui/react"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import Header from "Components/Header"
import { SkipNavContent, SkipNavLink } from "Components/SkipNav/index"
import Footer from "Components/Footer"
import theme from "../@chakra-ui/gatsby-plugin/theme"

const Layout = ({ children }) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)
  return (
    <ChakraProvider theme={theme}>
      <SEOContext.Provider value={{ global: seo }}>
        <SkipNavLink />
        <Header siteTitle={seo.schema.siteName} />
        <SkipNavContent>
          <Box pt={"69.38px"}>{children}</Box>
        </SkipNavContent>
        <Footer />
      </SEOContext.Provider>
    </ChakraProvider>
  )
}

export default Layout

// export default function Layout({ location, children }) {
//   /* eslint-disable no-unused-vars */
//   let rootPath = `/`
//   /* eslint-disable no-unused-vars */

//   if (typeof __PREFIX_PATHS__ !== `undefined` && window.__PREFIX_PATHS__) {
//     rootPath = __PATH_PREFIX__ + `/`
//   }

//   return <App location={location}>{children}</App>
// }

// function App({ children }) {
//   return (
//     <div className={cx("shell")}>
//       <Nav />
//       <div className={cx("app")}>{children}</div>
//       <Footer />
//     </div>
//   )
// }
